import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import store from 'client/store';
import classNames from 'classnames';
import t from 'i18n';

@observer
export class ToolbarBottom extends Component {
	render() {
		return <div className={classNames('bottom', this.props.className)}>{this.props.children}</div>;
	}
}

