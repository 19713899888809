const REGEX = {
	requireNumber: /\d/,
	requireUppercase: /[A-ZА-Я]/,
	requireLowercase: /[a-zа-я]/,
	requireSpecialChars: /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/,
};
export const checkPassword = (password, minLength, requireOptions = []) => {
	const invalid = [];
	if (password.length < minLength) {
		invalid.push('minLength');
	}
	for (const option of requireOptions) {
		if (REGEX?.[option]) {
			if (!REGEX[option].test(password)) invalid.push(option);
		}
	}
	return invalid;
};
