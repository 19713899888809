import React from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import t from 'i18n';

@observer
export class ToolbarCount extends React.Component {
	static propTypes = {
		text: PropTypes.string.isRequired,
		count: PropTypes.number,
	};

	static defaultProps = {
		text: t('total'),
	};

	constructor(props) {
		super(props);
	}

	render() {
		const { text, count } = this.props;

		return (
			<div className='toolbar-count'>
				<div>
					{text}: <span className='count'>{count}</span>
				</div>
			</div>
		);
	}
}

