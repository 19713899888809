import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Button as Button_ } from '@smartplatform/ui';
import PropTypes from 'prop-types';
import { Ripple } from '../ripple-effect';
import store from 'client/store';
import classNames from 'classnames';
import t from 'i18n';

export const Button = observer(({ className, text, startIcon, endIcon, disableRipple, children, centerRipple, ...restProps }) => {
	const isButtonWithIcon = !!(endIcon || startIcon);

	return (
		<Button_ className={classNames('base-block-button', className, { 'with-icon': isButtonWithIcon })} {...restProps}>
			{startIcon}
			{children || text}
			{endIcon}
			{!disableRipple && <Ripple center={centerRipple} />}
		</Button_>
	);
});

Button.defaultProps = {
	variant: 'primary',
};

Button.propTypes = {
	className: PropTypes.string,
	text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
	startIcon: PropTypes.node,
	endIcon: PropTypes.node,
	disableRipple: PropTypes.bool,
	disabled: PropTypes.bool,
	onClick: PropTypes.func,
	centerRipple: PropTypes.bool,
};

