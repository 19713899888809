import React from 'react';
import { CancelButton as CancelButton_ } from 'components';
import { ActionbarContext } from './Actionbar';
import store from 'client/store';

export const CancelButton = () => {
	const { form, cancelTitle, onCancel, noCancel } = React.useContext(ActionbarContext);

	if (noCancel) return null;

	const onBack = async () => {
		await form.back();
		onCancel?.();
	};

	const props = { onClick: onBack };

	if (cancelTitle) {
		props.text = cancelTitle;
	}

	return <CancelButton_ {...props} />;
};

