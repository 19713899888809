/**
 * Отдаёт массив required палей модели
 * @param model - валидная запись из стора
 */

export const getModelRequiredFields = (model) => {
	if (!model) throw Error('no model provided');
	const { PROPERTIES } = model;
	if (typeof PROPERTIES !== 'object') throw Error('no PROPERTY object in model');
	return Object.keys(PROPERTIES).filter((property) => PROPERTIES[property].required);
};

