import qs from 'qs';
import { createBrowserHistory } from 'history';
import { Rest, ModelStore } from '@smartplatform/model-store';
import { RouteStore } from '@smartplatform/route-store';
import { LocalStore } from '@smartplatform/local-store';
import UIStore from './ui';
import { io } from 'socket.io-client';
import { localStoreConfig } from './localStoreConfig';
import { observable } from 'mobx';
import { parseConfig } from 'client/tools/config/parseConfig';
import { mergeConfigs } from 'client/tools/config/mergeConfig';

/**
 * Глобальный контейнер сторов
 */
export class AppStore {
	local; // Локальное хранилище
	model; // Хранилище моделей
	route; // Хранилище маршрута браузера
	api; // API обмена с сервером
	admin; // Хранилище @admin
	ui; // ui
	socket; // вебсокет для общения с сервером
	config; // Конфигурация клиента
	serverConfig; // конфиг сервера;
	dbServerConfig; // настройки с БД;

	subscribers = {};

	@observable appKey = 0;
	@observable appSettings = {};

	constructor() {
		this.history = createBrowserHistory();
		this.history.listen(this.onHistoryChange);
		this.ui = new UIStore(this);
		this.local = new LocalStore();
		this.socket = new io();

		this.local.extend(localStoreConfig);
		this.local.save();

		this.rest = new Rest();
		this.model = new ModelStore({
			transport: this.rest,
			cache: false,
			autoConnect: false,
			onLogin: this.onLogin,
		});

		this.route = new RouteStore({
			history: this.history,
			decode: qs.parse,
			encode: qs.stringify,
		});
	}

	/**
	 * Вызывается при успешной авторизации и переподключает вебсокет
	 * @param user
	 */
	onLogin = async (user) => {
		// переподключаем вебсокет
		console.log(this.socket);
		await this.fetchDbServerConfig();
		this.socket.disconnect().connect();
	};

	get isAdmin() {
		return this.model.roles.map((i) => i.name).includes('admin');
	}

	get maxSelectItems() {
		return window.innerHeight > 1000 ? 15 : 10;
	}

	/**
	 * Инициализация стора
	 * @returns {Promise<void>}
	 */
	init = async () => {
		//! в этот объект надо замерджить еще серверный конфиг
		await this.model.connect();
		this.serverConfig = this.model.config || {};
		await this.fetchDbServerConfig();

		// переподключаем вебсокеты так как возможно пользователь уже авторизовался
		this.socket.disconnect().connect();

		document.body.className = this.local.isDarkMode ? 'dark-theme' : 'default-theme';
	};

	fetchDbServerConfig = async () => {
		const configRecords = await this.model.Config.find({ include: ['image'] });
		this.dbServerConfig = parseConfig(configRecords);
		this.mergedConfig = mergeConfigs({ serverConfig: this.serverConfig, dbConfig: this.dbServerConfig });
		this.ui.logo = this.mergedConfig?.operator?.logo ? `${this.mergedConfig?.operator?.logo}?v=${new Date().getTime()}` : null;
		this.appSettings.title = this.mergedConfig?.operator?.siteName || 'ХОРУС';
		this.appSettings.favicon = this.mergedConfig?.operator?.favicon;
	};

	subscribe = (event, callback) => {
		if (!this.subscribers[event]) this.subscribers[event] = [];
		this.subscribers[event].push(callback);
	};

	unsubscribe = (event, callback) => {
		if (this.subscribers[event]) {
			const index = this.subscribers[event].findIndex((cb) => cb === callback);
			if (index !== -1) this.subscribers[event].splice(index, 1);
			if (this.subscribers[event].length === 0) delete this.subscribers[event];
		}
	};

	fireHMR = () => {
		// console.log('HMR!');
		if (this.subscribers.hmr) this.subscribers.hmr.forEach((cb) => cb());
	};

	onHistoryChange = (e) => {
		// console.log('onHistoryChange', e);
		if (this.subscribers.history) this.subscribers.history.forEach((cb) => cb(e));
	};
}

window.APP_STORE = new AppStore();

export default window.APP_STORE;
