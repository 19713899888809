import React from 'react';
import t from 'i18n';
import { fioShort, relative } from 'client/tools';
import { LabelField } from './LabelField';
import classNames from 'classnames';
import './lastEditField.scss';

export class LastEditField extends React.Component {
	render() {
		// опускаем либо user , либо по отдельности
		let { className, label = t('lastEditDate'), user, date, record } = this.props;
		user = user || record.editor;
		date = date || record.updatedAt;
		className = classNames('last-edit-field', className);
		if (!user || !date || this.isLoading) return;
		user = fioShort(user);
		date = relative(date);
		return <LabelField className={className} label={label} text={user + ' - ' + date} />;
	}
}
