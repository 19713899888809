import store from 'client/store';
import { observable, action } from 'mobx';
import { BUTTONS } from '../graphicReport/constants';
import { endOfDay, format, startOfDay } from 'date-fns';
import i18next from 'i18next';
import { COLUMNS, EGT_COLUMNS } from './constants';
import { CONFIGURATION_INCLUDE } from 'client/pages/planes/constants';
import _ from 'lodash';
import { generateColor } from 'client/tools/generateColor';

//todo переписать логику, добавить в фазы поле required, чтобы брать фазы по этому свойству, а не хордокодом

export class trendReportStore {
	@observable flightPhase = BUTTONS[0];
	@observable items = {};
	@observable selectedColumns = COLUMNS;
	@observable mergedColumns = [];
	@observable aircraft = null;
	@observable records = [];
	@observable isEdit = false;
	@observable isLoading = false;
	@observable startDate = null;
	@observable endDate = null;
	@observable key = 0;
	@observable page = 1;
	@observable zoomedColumns = {};
	@observable buttonIsPressed = true;
	@observable activeEngines = [];
	@observable isSmoothed = false;

	// Селект конфигурации устанавливаемых двигателей
	enginesConfigurationItems = [];
	@observable configuration = null;

	perPage = 30;
	selectedFlightPhase = null;
	localStorage = store.local.aircraft.trendsReport;
	smoothingCoefficient = 0.2;

	buttons = observable.array([]);

	constructor(aircraftId, aircraft) {
		this.id = aircraftId;
		this.aircraft = aircraft;
		this.zoomedColumns = {
			ZVB1F_1: { min: 0, max: 5 },
			ZVB1F_2: { min: 0, max: 5 },
			ZVB1R_1: { min: 0, max: 5 },
			ZVB1R_2: { min: 0, max: 5 },
			ZVB2F_1: { min: 0, max: 5 },
			ZVB2F_2: { min: 0, max: 5 },
			ZVB2R_1: { min: 0, max: 5 },
			ZVB2R_2: { min: 0, max: 5 },
		};

		this.init();
	}

	@action
	addButton(property) {
		this.buttons.push({ property: property, isPressed: true });
	}

	@action
	setButtonState(property) {
		const button = this.buttons.find((b) => b.property === property);
		if (button) {
			button.isPressed = !button.isPressed;
		}
	}

	getButtonState(property) {
		const button = this.buttons.find((b) => b.property === property);
		return button ? button.isPressed : true;
	}

	onChangeEngine = (engine) => () => {
		const index = this.activeEngines.findIndex((value) => value === engine);
		index !== -1 ? this.activeEngines.splice(index, 1) : this.activeEngines.push(engine);
	};

	onChangeSmoothed = () => (this.isSmoothed = !this.isSmoothed);

	styleEngineBtn = (engine) => (this.activeEngines.find((item) => item === engine) ? 'engine-button-wrapper active-engine-btn' : 'engine-button-wrapper');

	init = async () => {
		this.isLoading = true;
		if (store.mergedConfig?.smoothingCoefficient) this.smoothingCoefficient = store.mergedConfig.smoothingCoefficient;
		await store.fetchDbServerConfig();
		await this.getEnginesConfiguration();
		this.flightPhase = this.localStorage.flightPhase ?? this.flightPhase;
		this.selectedFlightPhase = await this.fetchFlightPhase();
		const param = await this.fetchParameter('zt49');
		const value = await this.fetchParameterValues(param.id);

		EGT_COLUMNS.forEach((item) => {
			item.maxLimit = value;
		});

		this.selectedColumns = [...COLUMNS, ...EGT_COLUMNS];

		if (this.localStorage.selectedColumns[this.flightPhase.phaseName]?.length) {
			for (const obj of this.selectedColumns) {
				const isSelected = this.localStorage.selectedColumns[this.flightPhase.phaseName].find((columns) => columns.label === obj.label)?.isSelected;

				this.selectedColumns = this.selectedColumns.map((column) => {
					if (column.label === obj.label) {
						return { ...column, isSelected: isSelected };
					}
					return column;
				});
			}
		}

		this.startDate = this.localStorage.startDate && new Date(this.localStorage.startDate);
		this.endDate = this.localStorage.endDate && new Date(this.localStorage.endDate);

		const where = {
			aircraftId: this.id,
			phaseId: this.selectedFlightPhase.id,
			and: [],
		};

		if (this.startDate) {
			where.and.push({
				flightTime: {
					gte: startOfDay(this.startDate),
				},
			});
		}

		if (this.endDate) {
			where.and.push({
				flightTime: {
					lte: endOfDay(this.endDate),
				},
			});
		}

		this.query = { where, order: 'flightTime desc' };

		try {
			this.records = await store.model.ViewInput.find(this.query);

			this.zoomedColumns = {
				ZVB1F_1: { min: 0, max: 5 },
				ZVB1F_2: { min: 0, max: 5 },
				ZVB1R_1: { min: 0, max: 5 },
				ZVB1R_2: { min: 0, max: 5 },
				ZVB2F_1: { min: 0, max: 5 },
				ZVB2F_2: { min: 0, max: 5 },
				ZVB2R_1: { min: 0, max: 5 },
				ZVB2R_2: { min: 0, max: 5 },
			};

			await this.initButtons();

			this.buttons.forEach((button) => {
				const result = this.selectedColumns.find((column) => button.property === column.property);
				if (button.property === result.property) {
					const prop = result.property;
					if (this.zoomedColumns.hasOwnProperty(prop)) {
						result.min = this.zoomedColumns[prop].min;
						result.max = this.zoomedColumns[prop].max;
					}
				}
			});
		} catch (e) {
			console.error('Fetching orders error:', e);
		}

		this.onMergeColumns();

		this.isLoading = false;
	};

	getEnginesConfiguration = async () => {
		const configurations = this.groupByProperty(
			await this.aircraft.configurations.find({ include: CONFIGURATION_INCLUDE, order: 'enginePosition asc' }),
			'date'
		);
		const plateEngines = await store.model.Engine.find({ where: { aircraftId: this.aircraft.id } });

		const currentPlaneConf = plateEngines
			.sort((a, b) => a.enginePosition - b.enginePosition)
			.map((r) => r.id)
			.join('/');

		this.enginesConfigurationItems = Object.values(configurations).map((r, index) => {
			const conf = r
				.sort((a, b) => a.enginePosition - b.enginePosition)
				.map((r) => r.engineId)
				.join('/');
			return {
				id: index,
				values: r,
				label: r.map((_r) => _r.engine.serialNumber).join('/'),
				configuration: conf,
				current: conf === currentPlaneConf,
			};
		});

		if (this.enginesConfigurationItems.length)
			this.configuration = this.enginesConfigurationItems.find((r) => !!r.current) || this.enginesConfigurationItems[0];
	};

	onChangeConfiguration = async (item) => {
		this.configuration = item;
	};

	groupByProperty = (data, property) => {
		const groups = data.reduce((acc, current, i) => {
			if (current[property]) {
				const key = current[property];

				if (!acc[key]) {
					acc[key] = [];
				}

				acc[key].push(current);
			}

			return acc;
		}, {});

		return groups;
	};

	initButtons() {
		const tempArray = Object.keys(this.zoomedColumns).map((key) => {
			return { property: key, isPressed: true };
		});

		tempArray.map((item) => {
			let button = item.property;
			this.addButton(button);
		});
	}

	getCurrentEnginesLine = (records) => {
		if (!this.enginesConfigurationItems.length) return [];
		const colors = {};

		const defaultProps = {
			animation: false,
			showSymbol: false,
			hoverAnimation: false,
			color: 'rgba(255, 41, 184, 0)',
			name: null,
			tooltip: {
				show: false,
			},
		};
		const charts = [];
		const currentConfiguration = this.configuration?.configuration;
		const maxValue = _.max(records.map((r) => r.val));

		for (const [index, data] of records.entries()) {
			if (data.enginesConfiguration != records?.[index - 1]?.enginesConfiguration) {
				if (charts.length) {
					const dateLength = charts[charts.length - 1].data.length;
					if (dateLength > 1) {
						charts[charts.length - 1].data[dateLength - 1] = [maxValue, data.date];
					} else {
						const oldValue = charts[charts.length - 1].data[dateLength - 1];
						charts[charts.length - 1].data[dateLength - 1] = [maxValue, data.date];
						charts[charts.length - 1].data.push(oldValue);
					}
				}
				const color = currentConfiguration === data.enginesConfiguration ? 'rgba(0,0,0,0)' : colors[data.enginesConfiguration] || generateColor(index);
				charts.push({
					type: 'line',
					data: [[maxValue, data.date]],
					configuration: data.enginesConfiguration,
					...defaultProps,
					areaStyle: {
						color: color,
						opacity: 0.3,
					},
				});
				if (!colors[data.enginesConfiguration]) colors[data.enginesConfiguration] = color;
			} else {
				charts[charts.length - 1].data.push([maxValue, data.date]);
			}
		}
		return charts;
	};

	getTrendOption = ({ flightTimeArr, values, limits = {}, zooming }) => {
		const { max, min } = limits;

		const segmentColorSeries = this.getCurrentEnginesLine(values);
		let markLine = { data: [] };
		(max || max === 0) &&
			markLine.data.push([
				{
					name: 'max',
					xAxis: max,
					y: 0,
					lineStyle: {
						color: 'red',
						type: 'solid',
					},
					symbol: 'none',
					label: {
						position: 'middle',
						show: true,
						backgroundColor: 'rgba(255,94,94,0.5)',
						padding: [5, 9, 6, 9],
						borderRadius: 5,
						fontWeight: 600,
						fontFamily: 'Arial',
						fontSize: 16,
					},
				},
				{
					xAxis: max,
					y: '100%',
				},
			]);

		(min || min === 0) &&
			markLine.data.push([
				{
					name: 'min',
					xAxis: min,
					y: 0,
					lineStyle: {
						color: 'red',
						type: 'solid',
					},
					symbol: 'none',
					label: {
						position: 'insideMiddleBottom',
						show: true,
						backgroundColor: 'rgba(255,94,94,0.5)',
						padding: [5, 10, 5, 10],
						borderRadius: 5,
						fontWeight: 600,
						fontFamily: 'Arial',
						fontSize: 16,
					},
				},
				{
					xAxis: min,
					y: '100%',
				},
			]);

		return {
			grid: {
				top: 10,
				left: 10,
				right: 20,
				bottom: 5,
				containLabel: true,
			},
			tooltip: {
				trigger: 'axis',
				axisPointer: {
					type: 'cross',
				},
			},
			yAxis: {
				type: 'category',
				axisLine: {
					show: false,
					onZero: false,
				},
				axisTick: {
					show: false,
				},
				axisLabel: {
					formatter: '',
				},
				data: flightTimeArr,
			},
			xAxis: {
				type: 'value',
				position: 'top',
				...zooming,
			},
			series: [
				{
					type: 'line',
					color: '#323232',
					step: 'middle',
					data: values.map((r) => r.val),
					markLine,
				},
				...segmentColorSeries,
			],
		};
	};

	getLabelOption = (flightTimeArr) => {
		return {
			grid: {
				top: 30,
				left: 0,
				right: 0,
				bottom: 5,
				containLabel: true,
			},
			yAxis: {
				type: 'category',
				axisLine: { show: false },
				axisTick: { show: false },
				axisLabel: {
					inside: true,
				},
				data: flightTimeArr,
			},
			xAxis: {},
		};
	};

	onViewChange = (property) => {
		if (!this.getButtonState(property)) {
			this.selectedColumns.find((column) => {
				if (column.property === property) {
					const prop = column.property;
					if (this.zoomedColumns.hasOwnProperty(prop)) {
						column.min = this.zoomedColumns[prop].min;
						column.max = this.zoomedColumns[prop].max;
					}
				}
			});
			this.setButtonState(property);
		} else {
			this.selectedColumns.find((column) => {
				if (column.property === property) {
					const prop = column.property;
					if (this.zoomedColumns.hasOwnProperty(prop)) {
						column.min = 0;
						column.max = 5;
					}
				}
			});
			this.setButtonState(property);
		}
	};

	onMergeColumns = () => {
		const mergedArray = [];
		const tempObject = {};

		for (const obj of this.selectedColumns) {
			if (!tempObject[obj.label]) {
				tempObject[obj.label] = { ...obj, properties: [] };
			}
			tempObject[obj.label].properties.push(obj.property);
		}

		for (const label in tempObject) {
			mergedArray.push(tempObject[label]);
		}

		this.mergedColumns = mergedArray;
	};

	onChange = (prop) => (value) => {
		this[prop] = value;
		this.init();
	};

	fetchParameterValues = async (paramId) => {
		const value = await store.model.ParameterLibrary.find({
			where: {
				phaseId: this.selectedFlightPhase.id,
				parameterId: paramId,
			},
		});

		return value[0].maxLimit;
	};

	fetchParameter = async (code) => {
		const param = await store.model.Parameter.find({
			where: {
				code: {
					ilike: code,
				},
			},
			fields: ['id'],
		});

		return param[0];
	};

	fetchFlightPhase = async () => {
		const selectedPhase = (
			await store.model.Phase.find({
				where: {
					name: this.flightPhase.phaseName,
				},
			})
		)[0];

		return selectedPhase;
	};

	onPhaseChange = async (value) => {
		this.flightPhase = value;

		this.localStorage.flightPhase = this.flightPhase;
		store.local.save();
		await this.init();
	};

	onAircraftChange = async (value) => {
		this.aircraft = value;
		this.id = this.aircraft.id;
		await this.init();
	};

	onDateChange = (prop) => async (object) => {
		const { value, e } = object;
		this[prop] = new Date(value);
		this.localStorage[prop] = value;
		store.local.save();

		this.init();
	};

	onToggleEdit = (isEdit) => () => {
		this.isEdit = isEdit;
	};

	onEditConfirm = () => {
		this.onToggleEdit(false)();
	};

	getSegmentsByConf = (prop) => {
		const segments = [];
		for (const [index, data] of this.records.entries()) {
			if (data.enginesConfiguration != this.records?.[index - 1]?.enginesConfiguration) {
				segments.push({
					data: [{ val: data[prop], date: data.flightTime }],
					configuration: data.enginesConfiguration,
				});
			} else {
				segments[segments.length - 1].data = [...segments[segments.length - 1].data, { val: data[prop], date: data.flightTime }];
			}
		}
		return segments;
	};
}
