import React from 'react';
import { action, observable } from 'mobx';
import { observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';

import { Button, Field, Row, Form, Loader } from '@smartplatform/ui';
import ACL from './ACL';
import { DeleteButton } from 'components';
import store from 'client/store';
import t from 'i18n';

@withRouter @observer
export default class Edit extends React.Component {
	
	@observable record = null;
	@observable error = null;
	@observable isLoading = true;
	@observable isSaving = false;
	
	constructor(props) {
		super(props);
		store.ui.title = t('role.title');
		this.init();
	}
	
	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.id !== this.props.match.params.id) {
			this.init();
		}
	}
	
	@action init = async () => {
		this.isLoading = true;
		this.id = this.props.match.params.id;
		if (this.id) {
			this.error = null;
			try {
				this.record = await store.model.Role.findById(this.id);
			} catch (e) {
				this.error = e.message;
			}
		} else {
			this.record = new store.model.Role();
		}
		this.isLoading = false;
	};
	
	setACLInstance = instance => this.aclInstance = instance;
	
	save = async () => {
		this.isSaving = true;
		try {
			await this.record.save();
			if (this.id && this.aclInstance) {
				await this.aclInstance.save();
				this.aclInstance.init();
				store.route.push({ path: `/roles/` });
			}
			if (!this.id) {
				store.route.push({ path: `/roles/${this.record.id}` });
			}
		}
		catch(e) {
			this.error = e.message;
		}
		this.isSaving = false;
	};
	
	delete = async () => {
		await this.record.delete();
		if (this.aclInstance) {
			this.aclInstance.deleteAll();
		}
		store.route.push({ path: '/roles' });
	};
	
	render() {
		if (this.isLoading) return '...';
		if (this.error) return <div className="error">{this.error}</div>;
		
		return <div className="role-edit">
			<Form record={this.record} noSave noDelete>
				<Row>
					<Field property="name" label={t('role.name')} />
					<Field property="description" label={t('role.description')} />
					<div />
				</Row>
			</Form>

			{this.record.id && <ACL role={this.record} instance={this.setACLInstance} />}

				<div className={'actions' + (this.isSaving ? ' disabled' : '')}>
					<Button onClick={this.save} variant="primary">{t('save')}</Button>
					{this.record.id && <DeleteButton onConfirm={this.delete} />}
					{this.isSaving && <span className="saving"><Loader size={18} /> {t('saving')}</span>}
				</div>
		</div>;
	}
};
