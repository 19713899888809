import React from 'react';
import PropTypes from 'prop-types';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { UserAvatar } from './UserAvatar';
import './style.scss';

@observer
export class MembersAvatars extends React.Component {
	static propTypes = {
		members: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
		size: PropTypes.number,
		avatarsCount: PropTypes.number,
		gap: PropTypes.number,
		badge: PropTypes.bool,
		onMemberClick: PropTypes.func,
	};

	static defaultProps = {
		size: 38,
		avatarsCount: null,
		gap: 5,
		badge: false,
	};

	@observable popup = LOADING;

	constructor(props) {
		super(props);
	}

	render() {
		const { members, size, avatarsCount, style, badge, onMemberClick, selectedId } = this.props;
		const avatars = avatarsCount ? members.slice(0, avatarsCount) : members;
		const diff = members.length - avatars.length;

		return (
			<div className='avatars'>
				{avatars.map((member, i) => (
					<div className='avatar-wrapper' onClick={() => (onMemberClick ? onMemberClick(member) : {})} key={member.id}>
						<UserAvatar
							selected={selectedId === member.user.id}
							user={member.user}
							role={member.role}
							size={size}
							style={style}
							badge={badge && member.user.tasks().length > 0 ? member.user.tasks().length : undefined}
						/>
					</div>
				))}
				{diff > 0 && <span className='more'>{`+${diff}`}</span>}
			</div>
		);
	}
}

