import React from 'react';
import { Switch, Route } from 'react-router-dom';

import List from './List';
import Edit from './Edit';

const path = '/roles';
const modelName = 'Role';

export const Roles = () => <Switch>
	<Route path={path} exact children={({ match }) => <List match={match} modelName={modelName} />}/>
	<Route path={`${path}/page/:page`} children={({ match }) => <List match={match} modelName={modelName} />}/>
	<Route path={`${path}/create`} component={Edit} />
	<Route path={`${path}/:id`} component={Edit} />
	{/*<Route path={`${path}/:id`} children={({ match }) => <Edit match={match} path={path} modelName={modelName} />}/>*/}
</Switch>;
