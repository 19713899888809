export default {
	translation: {
		id: 'ID',
		removed: 'Убран',
		total: 'Всего',
		ok: 'Хорошо',
		clearAll: 'Очистить все',
		selectAll: 'Выбрать все',
		removeAll: 'Убрать все',
		confirm: 'Подтвердить',
		allTime: 'All time',
		loading: 'Загрузка',
		create: 'Создать',
		delete: 'Удалить',
		cancel: 'Отмена',
		save: 'Сохранить',
		reset: 'Сбросить',
		administration: 'Администрирование',
		roleMappings: 'Назначения ролей',
		installed: 'установлено',
		roles: 'Роли',
		acls: 'Права',
		uploadDate: 'Дата загрузки',
		open: 'Открыть',
		openFile: 'Открыть файл',
		download: 'Скачать',
		downloadFile: 'Скачать файл',
		downloadPDF: 'Скачать PDF',
		downloadXLSX: 'Скачать XLSX',
		errorMessage: 'Данное название уже существует',
		engineErrorMessage: 'Данный серийный номер уже существует',
		mainInfoErrorMessage: 'Данный идентификатор уже существует',
		duplicateKeyValueViolatesUniqueConstraint: 'Повторяется уникальное значение',
		information: 'Информация',
		info: 'Информация',
		description: 'Описание',
		date: 'Дата',
		date1: 'Дата (от)',
		date2: 'Дата (до)',
		enable: 'Включено',
		disable: 'Выключено',
		auth: {
			TOKEN_ROTTEN: 'Срок действия токена истек',
			login: 'Авторизация',
			logout: 'Выйти',
			goBack: 'Назад',
			register: 'Зарегистрироваться',
			restore: 'Восстановление пароля',
			next: 'Далее',
			email: 'E-mail',
			lastName: 'Фамилия',
			firstName: 'Имя',
			middleName: 'Отчество',
			password: 'Пароль',
			order: 'Получить доступ',
			enterEmail: 'Введите E-mail',
			enterLogin: 'Введите логин',
			enterPassword: 'Введите пароль',
			repeatPassword: 'Повторите пароль',
			enterRepeatedPassword: 'Повторите пароль',
			repeat: 'Повторите',
			minPasswordLength: 'Минимальная длина пароля',
			invalidEmail: 'Некорректный E-mail',
			emailExists: 'Такой E-mail уже зарегистрирован',
			registrationSuccess: 'На ваш E-mail ($email) была выслана ссылка для подтверждения регистрации.',
			noMatch: 'Пароли не совпадают',
			enterCode: 'Введите код',
			forgotPassword: 'Забыли пароль?',
			recover: 'Восстановить',
			required: 'Необходимо заполнить поле',
			wait: 'Подождите',
			seconds: 'секунд',
			limitExceeded: 'Превышен лимит попыток',
			tryAgain: 'Введите логин / пароль',
			registration: 'Регистрация',
			captcha: 'Код с картинки',
			simplePassword: 'Пароль не соответствует требованиям',
		},
		deleteFile: 'Удалить файл',
		login: 'Войти',
		logout: 'Выйти',
		notifyRecipient: {
			plural: 'Получатели рассылки',
			title: 'Получатель',
			name: 'Имя',
			email: 'Email',
			type: 'Тип события',
		},
		notifyRecipientGroup: {
			plural: 'Группы получателей рассылки',
			title: 'Группа',
			name: 'Имя',
			email: 'Email',
		},
		period: {
			at: 'at',
			title: 'Период',
			start: 'Начало периода',
			end: 'Конец периода',
			select: 'Период',
			day: 'День',
			week: 'Неделя',
			month: 'Месяц',
			quarter: 'Квартал',
			year: 'Год',
			everyday: 'Каждый день',
			everyweek: 'Каждую неделю',
			everymonth: 'Каждый месяц',
			lastDayOfMonth: 'Последний день месяца',
			dayOfMonth: 'числа каждого месяца`',
		},
		day: {
			sunday: 'Воскресенье',
			monday: 'Понедельник',
			tuesday: 'Вторник',
			wednesday: 'Среда',
			thursday: 'Четверг',
			friday: 'Пятница',
			saturday: 'Суббота',
		},
		transportData: {
			title: 'Транспортный модуль',
			active: 'Активность',
			roles: 'Роли',
			text: 'Текст',
			subject: 'Заголовок письма',
			period: 'Период',
			schedule: 'Установленное расписание',
			report: 'Отчет',
			nextDate: 'Дата следующей отправки',
			disabled: 'Рассылка неактивна',
			minute: 'Мин',
			hour: 'Час',
			day: 'День месяца',
			dayOfWeek: 'День недели',
		},
		editRecord: 'Редактирование',
		parameter: {
			IBE1: 'Клапан отбора воздуха от двигателя 1',
			IBE2: 'Клапан отбора воздуха от двигателя 2',
			IVS12: 'Запорный клапан',
			IVS13: 'ISOLATION_VALVE_(RIGHT)',
			IBP1: 'Клапан кондиционирования левой системы',
			IBP2: 'Клапан кондиционирования правой системы',
			IAI: 'Клапан обогрева крыла',
			IAIE1: 'Клапан обогрева воздухозаборника двигателя 1',
			IAIE2: 'Клапан обогрева воздухозаборника двигателя 2',
			ZALT: 'Высота, на которой были сняты показания (футы)',
			ZALT: 'Высота, на которой были сняты показания (футы)',
			ZT1A: 'Температура за бортом (°C)',
			ZXM: 'Скорость ВС (mach)',
			CAS: 'Вычисленная воздушная скорость (узел)',
			SAT: 'Статическая температура воздуха (°C)',
			AGW: 'Фактический вес ВС перед взлётом (тонн)',
			ZPCN12: 'Скорость вращения компрессора низкого давления двигателя (%)',
			ZPCN25: 'Скорость вращения  компрессора высокого давления двигателя (%)',
			ZT49: 'Температура выходящих газов на выходе из двигателя (°C)',
			ZT49_M: 'EGT Margin (°C)',
			ZVB1F: 'Вибрация вентилятора  вала компрессора низкого давления (миллидюйм)',
			ZVB2R: 'Вибрация вала компрессора высокого давления (миллидюйм)',
			ZVB2F: 'Вибрация  вала турбины высокого давления (миллидюйм)',
			ZVB1R: 'Вибрация вала турбины низкого давления (миллидюйм)',
			ZWF36: 'Расход топлива двигателя (кг/час)',
			ZPOIL: 'Давление масла в двигателе (PSI)',
			ZTOIL: 'Температура масла в двигателе (°C)',
			flightTime: 'Время полета',
			C_N1: 'Максимально допустимая тяга из расчета погодных условий (%)',
			C_N2: 'Максимально допустимая тяга из расчета погодных условий',
			C_DOP: 'Допустимый перепад давления масла',
			C_FFD: 'Отклонение по расходу топлива',
			ZTLA: 'THROTTLE_LEVER_ANGLE',
			ZVSV: 'VARIABLE_STATOR_VANE_POS',
			'PW100-ID': 'Identifier',
			'PW100-SN': 'Engine serial',
			'PW100-FDATE': 'Flight date',
			'PW100-REF': 'Reference',
			'PW100-CYC': 'Cycle',
			'PW100-PRESS': 'Pressure altitude',
			'PW100-OAT': 'Outside air temperature',
			'PW100-IAS': 'Indicated air speed',
			'PW100-TORQUE': 'Torque',
			'PW100-NP': 'Propeller speed',
			'PW100-NL': 'Low compressor speed',
			'PW100-NH': 'High compressor speed',
			'PW100-ITT': 'Inter turbine temperature',
			'PW100-WF': 'Fuel flow',
			'PW100-UWF': 'Units of fuel flow',
			'PW100-MNT': 'Maintenance code',
			SaM146_HBAR1: 'Hбар1',
			SaM146_VPR1: 'Vпр1',
			SaM146_TNV: 'Tнв',
			SaM146_N1: 'N1',
			SaM146_N2: 'N2',
			SaM146_TVG: 'Твг',
			SaM146_QT: 'Qт',
			SaM146_HPL: 'Хпл',
			SaM146_HKD: 'Хкд',
			SaM146_VIB: 'Виб',
			SaM146_RM: 'Рм',
			SaM146_TM: 'Тм',
		},
		phase: {
			title: 'Flight phase',
		},
		plane: {
			title: 'Самолет',
			plural: 'Самолеты',
		},
		aircraft: {
			title: 'Самолет',
			name: 'Plane',
			plural: 'Самолеты',
			mainInfo: 'Основная информация',
			duplicateKeyValueViolatesUniqueConstraint: 'Одновременно не могут быть два самолета с одинаковым ID',
			isSmoothed: 'Сглаживание',
			graphicReport: {
				title: 'Графический отчет',
				noData: 'Нет данных',
				delta: 'Смещение',
				chartSettings: 'Настройки графиков',
				engineColors: 'Цвета двигателей',
				pdf: {
					aircraft: 'Самолет',
					allTime: 'За все время',
					from: 'с',
					to: 'до',
					operator: 'Оператор',
					engine: 'Двигатель',
					phase: 'Фаза полета',
					smoothing: 'Сглаживание',
					enable: 'Включено',
				},
			},
			tableReport: {
				title: 'Табличный отчет',
				editReport: 'Редактировать отчет',
			},
			trendsReport: {
				title: 'Отчет по трендам',
			},
			serialNumber: 'ID самолета',
			create: 'Создать самолет',
			new: 'Новый самолет',
			weightUnits: 'Единицы веса',
			configuration: 'Конфигурация двигателей',
			language: 'Язык отчетов',
			period: {
				title: 'Период',
				week: 'Неделя',
				month: 'Месяц',
				threeMonths: 'Три месяца',
				quarter: 'Квартал',
			},
		},
		aircraftFamily: {
			plural: 'Семейства ВС ',
			title: 'Семейство ВС',
			name: 'Название',
			numberEngines: 'Количество двигателей',
			saveInvalidMsg: 'Невозможно сохранить, запись существует',
			deleteInvalidMsg: 'Невозможно удалить, пока есть привязанные Типы ВС',
			isScrew: 'Винтовой самолет',
			isScrewHint: 'Влияет на отображаемую иконку в разделе "Самолеты"',
		},
		aircraftManufacturer: {
			plural: 'Производители ВС',
			title: 'Производитель ВС',
			saveInvalidMsg: 'Невозможно сохранить, запись существует',
			deleteInvalidMsg: 'Невозможно удалить, пока есть привязанные Семейства ВС',
		},
		aircraftOperator: {
			plural: 'Операторы',
			title: 'Оператор',
			siteTitle: 'Наименования системы',
			name: 'Название оператора',
			saveInvalidMsg: 'Невозможно сохранить, запись существует',
			deleteInvalidMsg: 'Невозможно удалить, пока есть привязанные ВС',
			active: 'Активный оператор',
			onConfirm: 'Вы уверены что хотите сменить оператора?',
			attach: 'Логотип',
			uploadTitle: 'Загрузить логотип',
		},
		aircraftHolder: {
			plural: 'Владельцы ВС',
			title: 'Владелец ВС',
			name: 'Название владельца',
			saveInvalidMsg: 'Невозможно сохранить, запись существует',
			deleteInvalidMsg: 'Невозможно удалить, пока есть привязанные ВС',
		},
		aircraftType: {
			plural: 'Типы ВС',
			title: 'Тип ВС',
			saveInvalidMsg: 'Невозможно сохранить, запись существует',
			deleteInvalidMsg: 'Невозможно удалить, пока есть привязанные ВС',
		},
		engine: {
			plural: 'Двигатели',
			title: 'Двигатель',
			name: 'Engine',
			serialNumber: 'Серийный номер',
			thrustRating: 'Рейтинг тяги',
			enginePosition: 'Позиция двигателя',
			deleteInvalidMsg: 'Двигатель назначен ВС либо в статусе ONWING',
		},
		engineManufacturer: {
			name: 'Название производителя',
			plural: 'Производители двигателей',
			title: 'Производитель двигателей',
			saveInvalidMsg: 'Невозможно сохранить, запись существует',
			deleteInvalidMsg: 'Невозможно удалить, пока есть привязанные семейства двигателей',
		},
		engineConfig: {
			plural: 'Конфиги двигателей',
			title: 'Конфиг двигателя',
		},
		engineFamily: {
			plural: 'Семейства двигателей',
			title: 'Семейство двигателей',
			name: 'Семейство двигателей',
			numberBlades: 'Количество лопаток',
			deleteInvalidMsg: 'Невозможно удалить, пока есть привязанные типы двигателя',
			saveInvalidMsg: 'Невозможно сохранить, запись существует',
		},
		engineHolder: {
			plural: 'Владельцы двигателей',
			title: 'Владелец двигателя',
			name: 'Название владельца',
			deleteInvalidMsg: 'Невозможно удалить, пока есть привязанные двигатели',
			saveInvalidMsg: 'Невозможно сохранить, запись существует',
		},
		engineType: {
			plural: 'Типы двигателя',
			title: 'Тип двигателя',
			name: 'Название типа',
			code: 'Код типа',
			deleteInvalidMsg: 'Невозможно удалить, пока есть привязанные двигатели',
			saveInvalidMsg: 'Невозможно сохранить, запись существует',
		},
		onwingEngine: {
			plural: 'Привязка двигателей к ВС',
			title: 'Привязка двигателей к ВС',
		},
		engineStatus: {
			title: 'Статус двигателя',
			plural: 'Статусы двигателей',
			saveInvalidMsg: 'Невозможно сохранить, запись существует',
			deleteInvalidMsg: 'Ошибка удаления. Статус двигателя используется',
			name: 'Статус',
			color: 'Цвет',
			code: 'Код статуса',
		},
		logbook: {
			title: 'Журнал загрузки',
			plural: 'Журналы загрузки',
			archive: {
				noSorted: 'Не сортировано',
			},
		},
		importLog: {
			uploadDate: 'Дата загрузки',
			flightDate: 'Дата полета',
			filename: 'Название файла',
			file: 'Файл',
			status: 'Статус загрузки',
			fileSize: 'Размер файла',
			fileContent: 'Содержимое файла',
			noFileContent: 'Содержимое файла отсутствует',
			success: 'Успешно',
			error: 'Ошибка',
		},
		logs: {
			success: 'Успешно загружено',
			error: 'Загрузка не удалась, так как данные за этот период времени уже присутствуют в базе',
			duplicate: 'Файл с такими данными уже загружен',
			lengthIsNotEqual: 'Разное количество параметров и значений',
			notAllGroupsExist: 'Некоторые из групп DELIMITER, *FORMAT, *VALUES отсутствуют',
			notAllParamsCorrect: 'Параметры не совпадают с эталонным списком',
			incorrectParams: 'Неверные параметры в файле',
			invalidDate: 'Неверная дата полета',
			absenceAir: 'Отсутствует самолет с таким бортовым номером',
			absenceEngine: 'Не найден двигатель',
			invalidQuantityParam: 'Неверное количество параметров',
			invalidParams: 'Некорректный набор параметров',
			invalidParamsWithLimit: 'Загружены не все параметры',
			invalidQuantityFormatAndValues: 'Не соответствует количество параметров в описательной части с количеством данных',
			invalidLength: 'Присутствуют строки с некорректным количеством символов',
			invalidQuantityLine: 'Отсутствуют некоторые строки',
			invalidAircraftOrEngine: 'Неподходящий производитель самолета или двигателя',
			invalidID: 'Некорректное значение id',
			invalidValue: 'Присутствуют параметры с некорректными значениями',
			unhandledError: 'Произошла неизвестная ошибка',
			absentDataSelection: 'Отсутствует лист DataSelection',
			absentPhase: 'Невозможно опредлить режим',
			invalidDateTypes: 'Ошибка определения aft / fwd',
			invalidValueParams: 'Найдены неверные или отсутствующие значения параметров при импорте',
			invalidTimeParams: 'Найдены параметры с отсутствующим  временем',
			absenceAirNum: 'Отсутствует бортовой номер в файле',
			invalidDelimiter: 'Неверный "разделитель"',
		},
		roleMapping: {
			title: 'Назначение роли',
			plural: 'Назначенные роли',
			create: 'Новое назначение роли',
		},
		role: {
			title: 'Роль',
			plural: 'Роли',
			new: 'Новая роль',
			name: 'Название',
			description: 'Описание',
		},
		acl: {
			title: 'Право',
			plural: 'Права',
			create: 'Новое право',
			principalType: 'Вид субъекта',
			app: 'Приложение',
			role: 'Роль',
			user: 'Пользователь',
			accessType: 'Действие',
			read: 'Чтение',
			write: 'Запись',
			execute: 'Выполнение',
			all: 'Все',
			principalId: 'Субъект',
			model: 'Модель',
			permission: 'Право',
			allow: 'Разрешить',
			deny: 'Запретить',
			property: 'Свойство',
		},
		user: {
			recoverEmailSended: 'На указанный Вами адрес эл.почты отправлено письмо с инструкциями для восстановления пароля',
			isBlocked: 'Заблокирован',
			title: 'Пользователь',
			plural: 'Пользователи',
			new: 'Новый пользователь',
			phone: 'Телефон',
			email: 'E-mail',
			emptyEmail: 'Введите E-mail',
			invalidEmail: 'Некорректный E-mail',
			emailVerified: 'E-mail подтвержден',
			name: 'Логин',
			loginOrEmail: 'Логин / E-mail',
			password: 'Пароль',
			firstName: 'Имя',
			lastName: 'Фамилия',
			middleName: 'Отчество',
			profile: 'Профиль',
			leftComment: 'оставил(а) комментарий',
			createdTask: 'создал(а) задачу',
			telegram: 'Telegram ID',
			deletedUser: 'Неизвестный пользователь',
			emailAlreadyExists: 'Пользователь с таким E-mail уже существует',
			all: 'Все пользователи',
		},
		profile: {
			enterName: 'Введите имя',
			enterLastName: 'Введите фамилию',
			enterMiddleName: 'Введите отчество',
			phone: 'Номер телефона',
			enterId: 'Введите ID',
			title: 'Настройки профиля',
			addAvatar: 'Добавление фотографии',
			selectPicture: 'Выбрать фото',
			blocked: 'Вы собираетесь заблокировать учетную запись! Вы уверены?',
			notBlocked: 'Вы собираетесь разблокировать учетную запись! Вы уверены?',
			blockedStatus: 'Причина блокировки',
			protectionNoActivity: 'Защита от блокировки по неактивности',
		},
		password: {
			changed: 'Пароль изменен',
			hint: 'Пароль...',
			field: 'Пароль',
			input: 'Введите пароль',
			repeat: 'Повторите пароль',
			newPasswordHint: 'Оставьте это поле пустым, чтобы не менять пароль',
			empty: 'Введите пароль в оба поля',
			noMatch: 'Пароли не совпадают',
			match: 'Пароли совпадают',
			new: 'Новый пароль',
			validation: {
				minLength: 'Минимальная длина пароля: {{length}}',
				requireNumber: 'Наличие цифр',
				requireUppercase: 'Наличие заглавных символов',
				requireLowercase: 'Наличие строчных символов',
				requireSpecialChars: 'Наличие специальных символов',
				passwordOld: 'Введите старый пароль',
			},
			old: 'Старый пароль',
		},
		dictionary: {
			plural: 'Справочники',
			title: 'Справочник',
		},
		_settings: {
			title: 'Настройки',
			conversionFuelFlow: 'Конвертировать фунты в килограммы для FUEL_FLOW',
			mainInfo: 'Общие настройки',
			enableTableReportPagination: 'Пагинация в табличном отчете',
			smoothingCoefficient: 'Коэффициент сглаживания',
		},
		audit: {
			title: 'Журнал',
			model: 'Модель',
			action: 'Действие',
			create: 'Создание',
			delete: 'Удаление',
			update: 'Обновление',
			select: 'Выберите запись',
			data: 'Данные записи',
			owner: 'Пользователь',
			trendReport: 'Просмотр отчета по трендам',
			tableReport: 'Просмотр табличного отчета',
			graphicReport: 'Просмотр графического отчета',
			authorizationUser: 'Авторизация пользователя',
			unAuthorizationUser: 'Неудачная попытка авторизации',
			graphicReportPdfDownload: 'Выгрузка графического отчета в pdf',
			tableReportXlsxDownload: 'Выгрузка табличного отчета в Xlsx',
			tableReportPdfDownload: 'Выгрузка табличного отчета в pdf',
			hidden: '*скрыто*',
		},
		viewEngine: {
			serialNumber: 'Серийный номер',
			engineStatusName: 'Статус двигателя',
			engineTypeName: 'Тип двигателя',
			engineFamilyName: 'Семейство двигателей',
			engineManufacturerName: 'Производитель двигателей',
			engineHolderName: 'Владелец двигателя',
			aircraftSerialNumber: 'Самолет',
			plural: 'Двигатели',
		},
		export: 'Экспорт',
		passwordSettings: {
			title: 'Настройки пароля',
			minLength: 'Минимальная длина',
			requireNumber: 'Наличие цифр',
			requireUppercase: 'Наличие символов в верхнем регистре',
			requireLowercase: 'Наличие символов в нижнем регистре',
			requireSpecialChars: 'Наличие специальных символов',
			invalidRange: 'Значение дожно быть от {{min}} до {{max}}',
			invalidPasswordOptions: 'Включите хотя бы одну опцию сложности пароля',
			password: 'Пароль',
			passwordExpirationTime: 'Время действия пароля (кол-во. дней)',
			countSavedPasswords: 'Количество, сохраняемых паролей, для запрета совпадения',
			countUniqueCharts: 'Минимальное количество измененных символов в пароле',
		},
		authenticationSettings: {
			title: 'Настройки аутентификации',
			triesLimitBeforeCaptcha: 'Число попыток входа до защиты CAPTCHA',
			triesLimitBeforeBlock: 'Число попыток входа до блокировки пользователя',
			daysNoActivityBeforeBlocked: 'Кол-во. дней неактивности, до блокировки пользователя',
		},
		config: {
			saved: 'Настройки сохранены',
			blockedFieldsMessage: 'Заблокированные поля прописаны в конфиге сервера',
		},
		recover: 'Восстановить',
		change: 'Принять',
		operatorSettings: {
			title: 'Настройки оператора',
			siteName: 'Название сайта',
			logo: 'Логотип',
			favicon: 'Иконка сайта',
		},
		selectFile: 'Выберите файл',
		LOGIN_FAILED: 'Неверный логин/пароль',
		LOGIN_WAIT: 'Превышен лимит попыток',
		USER_BLOCKED: 'Учетная запись заблокирована',
		NO_SESSION_CAPTCHA: 'Не сгенерирован новый код',
		EMPTY_CAPTCHA: 'Не введен код',
		INVALID_CAPTCHA: 'Неверный код',
		AUTHORIZATION_REQUIRED: 'Необходимо авторизоваться',
		EMAIL_NOT_FOUND: 'Email не найден',
		Unauthorized: 'Необходимо авторизоваться',
		required: 'Необходимо заполнить поле',
		rewritePassword: 'Смена пароля',
		rewritePasswordHint: 'Срок действия пароля истек, смените пароль',
		record: {
			saved: 'Сохранено',
		},
		manualLocking: 'Ручная блокировка, пользователем "{{userShort}}" {{dateBlocked}}',
		invalidRange: 'Значение дожно быть от {{min}} до {{max}}',
	},
};
