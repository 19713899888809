import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import t from 'i18n';
import { MembersAvatars } from 'components';

@observer
export class ToolbarMembers extends Component {
	static propTypes = {
		members: PropTypes.array.isRequired,
		size: PropTypes.number,
		gap: PropTypes.number,
		badge: PropTypes.bool,
		avatarsCount: PropTypes.number,
	};
	static defaultProps = {
		size: 30,
		avatarsCount: 20,
		gap: 5,
		badge: true,
	};

	render() {
		const { badge, onMemberClick, selectedId, size, avatarsCount } = this.props;
		return (
			<div className='members'>
				<span className='title'>{t('project.members')}:</span>
				<MembersAvatars
					size={size}
					members={this.props.members}
					badge={badge}
					onMemberClick={onMemberClick}
					selectedId={selectedId}
					avatarsCount={avatarsCount}
				/>
			</div>
		);
	}
}

