import { observable } from 'mobx';
import store from 'client/store';
import debounce from 'lodash/debounce';
import { getPerPage } from 'client/tools';

// Базовый стор для стандартного листа с поиском, пагинацией, запросом
export class ListStore {
	@observable search = '';
	@observable querySearch = '';
	@observable order = 'id desc';
	@observable showFilters = false;
	@observable page = 1;
	@observable totalCount = 0;
	@observable perPage;
	@observable isPerPageSetted = false;

	// <Table> нужен контролируемый ререндер, поэтому query должен быть observable-свойством,
	// на которое можно влиять извне. Он не должен быть геттером (@computed из mobx не поможет).
	@observable query = {};

	model;
	init;
	path;
	filters;

	constructor({ filters = {}, path = '/', perPage = 10, model }) {
		this.filters = filters;
		this.path = path;
		this.model = model;
		this.perPage = perPage; // должно быть значение по умолчанию, чтобы первый compileQuery выдал правильный query
		this.doSearch = debounce(this.doSearch, 500, { leading: false, trailing: true });
		this.compileQuery(true);
	}

	get tableProps() {
		const tableProps = this.isPerPageSetted ? { model: this.model } : { rows: [] };
		const _tableProps = { key: tableProps.model, onRowClick: this.onRowClick, onQueryUpdate: this.onQueryUpdate, query: this.query };
		return { ...tableProps, ..._tableProps };
	}

	doSearch = () => {
		this.querySearch = this.search;
		this.compileQuery();
	};

	onSearch = (e) => {
		this.page = 1;
		this.search = e.target.value;
		this.doSearch();
	};

	onChange = (prop) => (value) => {
		this.page = 1;
		this[prop] = value;
		this.compileQuery();
	};

	setPerPage = (value) => {
		if (!value) value = getPerPage();
		this.page = 1;
		this.perPage = value;
		this.compileQuery();
		this.isPerPageSetted = true;
	};

	onQueryUpdate = (query) => {
		this.query = query;
		if (Number.isInteger(query._totalCount)) this.totalCount = query._totalCount;
		if (query.order === undefined) {
			this.order = 'id desc';
			this.query.order = this.order;
		} else {
			this.order = query.order;
		}
	};

	// Влияние извне, страницу сбрасываем, потому что пагинация тоже может измениться.
	updateFilters = (filters) => {
		this.page = 1;
		this.filters = filters;
		this.compileQuery();
	};

	compileQuery = () => {
		const { querySearch } = this;
		const query = {
			order: this.order,
			limit: this.perPage,
			skip: (this.page - 1) * this.perPage,
			...this.filters,
		};
		if (querySearch.length) query.search = querySearch;
		this.query = query;
	};

	create = () => store.route.push({ path: `${this.path}/create` });

	onRowClick = (record) => console.log(record) || store.route.push({ path: `${this.path}/${record.id}` });

	getInstance = (instance) => (this.tableInstance = instance);
}

