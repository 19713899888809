import React from 'react';
import { FormContext } from '@smartplatform/ui';
import { DeleteButton } from './DeleteButton';
import { SaveButton } from './SaveButton';
import { CancelButton } from './CancelButton';
export const ActionbarContext = React.createContext({});

import './style.scss';

export class Actionbar extends React.Component {
	static contextType = FormContext;

	constructor(props) {
		super(props);
	}

	render() {
		let { children, ...restProps } = this.props;
		const context = { ...this.context, ...restProps };

		if (!children)
			children = (
				<>
					<Actionbar.SaveButton />
					<Actionbar.CancelButton />
					<Actionbar.DeleteButton />
				</>
			);
		return (
			<ActionbarContext.Provider value={context}>
				<div className='custom-action-bar'>{children}</div>
			</ActionbarContext.Provider>
		);
	}
}

Actionbar.SaveButton = SaveButton;
Actionbar.CancelButton = CancelButton;
Actionbar.DeleteButton = DeleteButton;
