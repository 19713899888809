import React from 'react';
import PropTypes from 'prop-types';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { Avatar, Popover } from '@smartplatform/ui';
import { fio } from 'client/tools';
import './style.scss';

@observer
export class UserAvatar extends React.Component {
	static propTypes = {
		user: PropTypes.object.isRequired,
		size: PropTypes.number,
	};

	static defaultProps = {
		size: 18,
	};

	@observable popup = '';

	loadMember = async () => {
		const { user, size, role } = this.props;
		this.popup = (
			<>
				<div className='info'>
					<div className='fio'>{fio(user)}</div>
					{role && <div className='role'>{role.name}</div>}
				</div>
			</>
		);
	};

	render() {
		const { user, size, style, badge, selected } = this.props;

		return (
			<Popover
				className='member-avatar'
				contentClassName='member-avatar-popup'
				content={this.popup}
				onActivate={this.loadMember}
				onClose={this.onClose}
				style={style}
			>
				<Avatar user={user} size={size} badge={badge} className={selected ? 'selected' : ''} />
			</Popover>
		);
	}
}

