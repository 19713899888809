import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhoneAlt, faEnvelope, faPencilAlt } from '@fortawesome/free-solid-svg-icons';

import { DeleteIconButton } from 'components';
import { fio } from 'client/tools';
import store from 'client/store';
import './style.scss';

@observer
export default class UserCard extends React.Component {
	static propTypes = {
		user: PropTypes.object.isRequired,
		onRemove: PropTypes.func,
		onRemoveClick: PropTypes.func,
		confirmMessage: PropTypes.string,
		noDefaultEmail: PropTypes.bool,
	};

	gotoUser = (user) => store.route.push({ path: `/users/${user.id}` });

	render() {
		const { user, onRemove, onRemoveClick, confirmMessage } = this.props;
		const noDefaultEmail = this.props.noDefaultEmail ? !user.email.includes('@example.com') : true;

		return (
			<div className='user-card' key={user.id}>
				<div className='name'>
					{fio(user)}
					<div className='actions'>
						<FontAwesomeIcon icon={faPencilAlt} className='edit-btn' onClick={() => this.gotoUser(user)} />
						{onRemove && (
							<DeleteIconButton
								onActivate={onRemoveClick}
								onConfirm={() => onRemove(user)}
								confirmMessage={confirmMessage}
								portalClassName='user-card-confirm'
							/>
						)}
					</div>
				</div>
				<div className='info'>
					{user.phone && user.phone.trim().length > 0 && (
						<div className='phone'>
							<FontAwesomeIcon icon={faPhoneAlt} /> <a href={`tel:${user.phone}`}>{user.phone}</a>
						</div>
					)}
					{user.email && noDefaultEmail && user.email.trim().length > 0 && (
						<div className='email'>
							<FontAwesomeIcon icon={faEnvelope} /> <a href={`mailto:${user.email}`}>{user.email}</a>
						</div>
					)}
				</div>
			</div>
		);
	}
}
