import React, { Component } from 'react';
import { MenuItem } from './MenuItem';
import { SubMenu } from './SubMenu';
import PropTypes from 'prop-types';
import './style.scss';
import { SuggestInput } from '@smartplatform/ui';
import { observable } from 'mobx';
import store from 'client/store';
import { observer } from 'mobx-react';
import t from 'i18n';

@observer
export class RouteMenu extends Component {
	@observable searchValue = '';
	@observable reload = 0;
	suggestions = [];

	static propTypes = {
		items: PropTypes.array,
		search: PropTypes.bool,
		searchPlaceholder: PropTypes.string,
	};

	static defaultProps = {
		items: [],
		search: false,
		searchPlaceholder: t('search'),
	};

	constructor(props) {
		super(props);
		if (props.search) {
			this.suggestions = props.items.reduce((acc, item) => {
				item.path && item.title && acc.push(item);
				item.items?.forEach((item) => item.path && item.title && acc.push(item));
				return acc;
			}, []);
		}
	}

	onSearchValueChange = (e) => (this.searchValue = e);
	onSuggestionClick = (selectedValue) => {
		const { path } = this.suggestions.find((suggestion) => suggestion.title === selectedValue);
		this.searchValue = '';
		store.route.push({ path });
		this.reload++;
	};

	// items = [{ title, path }];
	// items = [{ title, items: [{ title, path }] }];

	render() {
		const { items, search, searchPlaceholder } = this.props;

		if (!items?.length) return <div className='error'>no menu items provided</div>;

		const filteredSuggestions = this.suggestions
			.filter((i) => i.title.toLowerCase().includes(this.searchValue.toLowerCase()))
			.map((i) => i.title)
			.sort((a, b) => a.localeCompare(b));

		return (
			<div className='route-menu'>
				{search && (
					<SuggestInput
						value={this.searchValue}
						suggestions={filteredSuggestions}
						className='suggest-input'
						onSuggestionClick={this.onSuggestionClick}
						onChange={this.onSearchValueChange}
						placeholder={searchPlaceholder}
					/>
				)}
				<div key={this.reload}>
					{items.map((item, index) => {
						const Component = Array.isArray(item?.items) ? SubMenu : MenuItem;
						return <Component {...item} key={item.title + index} />;
					})}
				</div>
			</div>
		);
	}
}
