import React, { Component, forwardRef } from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import store from 'client/store';
import t from 'i18n';
import classNames from 'classnames';

import './text.scss';

export const TruncateText = forwardRef(({ className, children, ...restProps }, ref) => {
	return (
		<div className={classNames('truncate', className)} {...restProps}>
			<span ref={ref}>{children}</span>
		</div>
	);
});

