import React from 'react';
import PropTypes from 'prop-types';

import './style.scss';

export default class Section extends React.Component {

	static propTypes = {
		className: PropTypes.string,
	};

	render() {
		const { children, className } = this.props;

		return <section className={'content-section ' + (className || '')}>
			{children}
		</section>;
	}

}
