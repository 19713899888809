import { faEnvelope, faPhone, faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";

const COLORS = {
	red: '#d00',
	green: '#37b813',
	orange: '#f80'
};

export const NOTIFY_COLORS = {
	error: COLORS.red,
	sended: COLORS.green,
	queued: COLORS.orange,
}

export const MASS_NOTIFY_COUNT = 200;
export const MESSAGE_TYPE_ICONS = {
	sms: faEnvelope,
	phone: faPhone,
	email: faEnvelope,
};
export const NOTIFY_CODE_ICONS = {
	true: { icon: faCheck, color: COLORS.green },
	false: { icon: faTimes, color: COLORS.red },
}