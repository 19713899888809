import React from 'react';
import { Switch, Route } from 'react-router-dom';

import List from './List';
import Edit from './Edit';
import './styles.scss';

const path = '/acls';

export const ACLs = () => <Switch>
	<Route path={path} exact><List /></Route>
	<Route path={`${path}/create`}><Edit /></Route>
	<Route path={`${path}/:id`}><Edit /></Route>
</Switch>;