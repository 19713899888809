import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { differenceInSeconds } from 'date-fns';
import t from 'i18n';

@observer
export class WaitTimer extends React.Component {
	@observable seconds = null;

	constructor(props) {
		super(props);
	}

	componentDidMount() {
		this.update();
	}

	update = () => {
		this.seconds = differenceInSeconds(new Date(this.props.time), new Date()) + 1;
		if (this.seconds > 0) {
			setTimeout(this.update, 1000);
		} else {
			this.props.onTimeout();
		}
	};

	render() {
		return (
			<div className='message'>
				{t('auth.wait')} {this.seconds} {t('auth.seconds')}
			</div>
		);
	}
}

