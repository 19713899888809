import React from 'react';
import { ActionbarContext } from './Actionbar';
import { DeleteButton as DeleteButton_ } from 'components';
import t from 'i18n';

export const DeleteButton = () => {
	const { form, deleteTitle, noDelete, disableDelete, record } = React.useContext(ActionbarContext);

	if (noDelete || !record.id) return null;

	const onDelete = async () => await form.delete();

	const defaultTitle = t('delete');

	return <DeleteButton_ onConfirm={onDelete} text={deleteTitle || defaultTitle} disabled={disableDelete} />;
};
