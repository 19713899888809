import React, { Component, createRef } from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { Popup } from '@smartplatform/ui';
import AvatarEditor from 'react-avatar-editor';
import { Button, DeleteIconButton } from 'components';
import store from 'client/store';
import t from 'i18n';

@observer
export class AvatarEdit extends Component {
	constructor(props) {
		super(props);
		this.ref = createRef();
	}

	setEditorRef = (editor) => (this.ref = editor);

	render() {
		const { disabledSave, onSave, onDelete, onClose, onChange, imageSrc, file, avatarEditorProps, disabledDelete, isNewRecord } =
			this.props;

		const _imageSrc = imageSrc.replace(/(\[.*?\])/gi, '[]');
		console.log(_imageSrc);
		return (
			<Popup onClose={onClose} className='avatar-image-popup'>
				<div className='avatar-image-block'>
					<h2>{t('profile.addAvatar')}</h2>
					<AvatarEditor ref={this.setEditorRef} image={file || _imageSrc} borderRadius={500} {...avatarEditorProps} />
					<div className='buttons'>
						<Button text={isNewRecord ? t('add') : t('save')} onClick={() => onSave(this.ref)} disabled={disabledSave} />
						<div className='button-attach-picture'>
							<input id='attachAvatar' onChange={onChange} type='file' accept='image/*' />
							<label htmlFor='attachAvatar'>
								<Button variant='default' text={t('profile.selectPicture')} />
							</label>
						</div>
						<DeleteIconButton onConfirm={onDelete} disabled={disabledDelete} />
					</div>
				</div>
			</Popup>
		);
	}
}

