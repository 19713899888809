import { observable } from 'mobx';
import store from 'client/store';
import { stringToBoolean } from 'client/tools';
import { triggerErrorNotification, triggerSaveNotification } from 'components/notifications';
import t from 'i18n';

export class SettingsStore {
	@observable settingProperties = {};
	@observable originalSettings = {};
	@observable updatedSettingsKeys = [];
	@observable isLoading = true;
	propsFields = null;
	disabledFields = new Set();
	serverConfig = {};
	settingsCode = null;
	@observable images = {};
	constructor(props) {
		if (props.propsFields) {
			this.propsFields = props.propsFields;
			for (const key in props.propsFields) {
				this.settingProperties[key] = props.propsFields[key].props.defaultValue || null;
			}
		}
		if (props.serverConfig) this.serverConfig = props.serverConfig;
		if (props.settingsCode) this.settingsCode = props.settingsCode;
		this.init();
	}

	init = async () => {
		if (this.serverConfig) {
			Object.keys(this.serverConfig).forEach((key) => {
				if (this.settingProperties[key] !== undefined) {
					this.settingProperties[key] = this.serverConfig[key];
					this.disabledFields.add(key);
				}
			});
		}
		const settings = await store.model.Config.find({
			where: { code: { inq: Object.keys(this.settingProperties).map((key) => this.generateCode(key)) } },
		});
		if (settings) {
			for (const setting of settings) {
				const key = setting.code.split('.').at(-1);
				if (!this.disabledFields.has(key)) await this.setConfigByModel(key, setting);
			}
		}
		this.dropOriginalSettings();
		this.isLoading = false;
	};

	generateCode = (property) => (this.settingsCode ? `${this.settingsCode}.${property}` : property);

	setConfigByModel = async (key, setting) => {
		const type = this.propsFields?.[key].type;
		if (type === 'boolean') {
			this.settingProperties[key] = setting.value ? stringToBoolean(setting.value) : false;
		} else if (type === 'number') {
			this.settingProperties[key] = isNaN(parseInt(setting.value)) ? 0 : parseInt(setting.value);
		} else if (type === 'range') {
			this.settingProperties[key] = isNaN(parseFloat(setting.value)) ? 0 : parseFloat(setting.value);
		} else if (type === 'attachment') {
			if (setting.imageId) {
				const attachment = await setting.image;
				this.settingProperties[key] = attachment.downloadFile('filename') + `?v=${attachment.updatedAt}`;
			}
		} else {
			this.settingProperties[key] = setting.value || '';
		}
	};

	dropOriginalSettings = () => {
		this.originalSettings = { ...this.settingProperties };
		for (const field in this.settingProperties) {
			this.originalSettings[field] = this.settingProperties[field];
		}
		this.updatedSettingsKeys = [];
	};

	onChange = (field) => (value) => {
		this.settingProperties[field] = value;
		this.setUpdatedSettingsKeys();
	};

	setUpdatedSettingsKeys = () =>
		(this.updatedSettingsKeys = Object.keys(this.originalSettings).filter((key) => this.settingProperties[key] !== this.originalSettings[key]));

	onSave = async () => {
		try {
			for (const key of this.updatedSettingsKeys) {
				const type = this.propsFields[key]?.type;
				const settingItem =
					(
						await store.model.Config.find({
							where: { code: this.generateCode(key) },
						})
					)?.[0] || new store.model.Config({ code: this.generateCode(key) });
				let value = this.settingProperties[key] === '' ? null : this.settingProperties[key];
				settingItem.value = value;
				if (type === 'attachment') {
					if (this.settingProperties[key]) {
						await this.setImage(settingItem, key);
					} else {
						if (settingItem.id) {
							await this.deleteImage(settingItem);
						}
					}
				} else {
					await settingItem.save();
				}
				if (this.propsFields[key].props.onSave) this.propsFields[key].props.onSave(settingItem);
			}
			this.dropOriginalSettings();
			await store.fetchDbServerConfig();
			triggerSaveNotification(t('config.saved'));
		} catch (e) {
			triggerErrorNotification(e.message);
		}
	};

	setImage = async (settingItem, key) => {
		settingItem.value = this.settingProperties[key].name;
		const attachment = settingItem.imageId ? await settingItem.image : null;
		if (attachment) {
			await attachment.deleteFile('filename');
			await attachment.delete();
			settingItem.image = null;
		}
		const _attachment = await new store.model.Attachment({ name: key, filename: settingItem.value });
		await _attachment.save();
		await _attachment.uploadFile('filename', this.settingProperties[key]);
		settingItem.imageId = _attachment.id;
		await settingItem.save();
	};

	deleteImage = async (settingItem) => {
		const attachment = await settingItem.image;
		if (attachment.id) await attachment.deleteFile('filename');
		await attachment.delete();
		await settingItem.delete();
	};
}
