import format from 'date-fns/format';
import formatRelative from 'date-fns/formatRelative';
import { ru } from 'date-fns/locale'

export const relative = date => {
	return date ? formatRelative(new Date(date), new Date, { locale: ru, weekStartsOn: 1 }) : null;
};

export const formatDate = (date, _format = 'dd.MM.yyyy') => date ? format(new Date(date), _format, { locale: ru, weekStartsOn: 1 }) : '';
export const formatTime = (int, _format = 'dd.MM.yyyy HH:mm:ss') => formatDate(new Date(int), _format);
